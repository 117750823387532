import React from 'react';
import { Helmet } from 'react-helmet';
import { getCookie } from 'pubweb-react-components/dist/utils/utils';
import { getQueryParams }  from 'pubweb-react-components/dist/utils/urlUtility';


export const getGAClientId = () => {
    if(typeof(window) !== 'undefined' && window.ga){
        return window.ga.getAll()[0].get("clientId")
    }

    return '';
};

export const getKenshooId = () => {
    return getQueryParams()["kenshooid"];
}

export const trackGAEvent = (name) => {
    return new Promise((resolve) => {
        if(window.dataLayer){
            window.dataLayer.push({
                event: name,
                eventName: name,
                eventCallback: resolve,
                eventTimeout: 2000
            });
        }
        else {
            resolve();
        }
    });
}

export const trackVWO = accountId => {
    if(typeof window !== 'undefined'){
        /* eslint-disable */
        var $ = window.vwo_$ || window.$ || window.jQuery;
        $ && $('.vwo_loaded').removeClass('vwo_loaded');
        window._vwo_code = (function () {
            var account_id = accountId,
                settings_tolerance = 2000,
                library_tolerance = 2500,
                use_existing_jquery = false,
                is_spa = 1,
                settings_timer,
                /* DO NOT EDIT BELOW THIS LINE */
                f = false,
                d = document;
            return {
                use_existing_jquery: function () {
                    return use_existing_jquery;
                },
                library_tolerance: function () {
                    return library_tolerance;
                },
                finish: function () {
                    if (!f) {
                        f = true;
                        var a = d.getElementById('_vis_opt_path_hides');
                        if (a) a.parentNode.removeChild(a);
                    }
                },
                finished: function () {
                    return f;
                },
                load: function (a) {
                    var b = d.createElement('script');
                    b.src = a;
                    b.type = 'text/javascript';
                    b.innerText;
                    b.onerror = function () {
                        console.log('error loading vwo');
                        _vwo_code.finish();
                    };
                    d.getElementsByTagName('head')[0].appendChild(b);
                },
                init: function () {
                    window.settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
                    var a = d.createElement('style'),
                        b = '', //CSS (Used for body tag typically.)
                        h = d.getElementsByTagName('head')[0]; a.setAttribute('id', '_vis_opt_path_hides');
                    a.setAttribute('type', 'text/css');
                    if (a.styleSheet) a.styleSheet.cssText = b;
                    else a.appendChild(d.createTextNode(b));
                    h.appendChild(a);
                    this.load('//dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&f=' + (+is_spa) + '&r=' + Math.random());
                    return settings_timer;
                }
            };
        }());
        window._vwo_settings_timer = window._vwo_code.init();
        /* eslint-enable */
    }
};